.authForm {
  width: 28rem;
  min-height: 35rem;

  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 2rem 3.2rem;

  /* box-shadow: inset; */
  background-color: white;
  /* box-shadow: 0px 10px 1px rgba(221, 221, 221, 1), 0 10px 20px rgba(204, 204, 204, 1); */
  border-radius: 1rem;
  svg {
    width: 70%;
  }
}


