.modalFootercont {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 1rem;
    .modalCancelBtn {
      border: none;
      color: #d91d1d;
      &:hover {
        color: #d91d1d  !important;
      }
    }
    .modalOkBtn {
      color: white;
      background-color: black;
      border: none;
      padding: 4px 36px;
      border-radius: 6px;
      &:hover {
        color: white  !important;
      }
    }
  }