.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: rgb(225, 255, 225);
  color: #1dd61d;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  border: 1px solid #1dd61d;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 80%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
