/* .wraper {
  min-height: calc(100vh);
  background: linear-gradient(
      0deg,
      rgba(21, 96, 189, 0.33) 0%,
      rgba(21, 96, 189, 0.33) 100%
    ),
    url("../../assets/BG_login.png"), lightgray 50% / cover no-repeat;
  background: url("../../assets/BG_login.png");
  background-repeat: no-repeat;
  background-size: cover;
} */
.authForm {
  width: 28rem;

  margin: 40px auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 3rem 3.2rem;

  /* box-shadow: inset; */
  background-color: white;
  /* box-shadow: 0px 10px 1px rgba(221, 221, 221, 1), 0 10px 20px rgba(204, 204, 204, 1); */
  border-radius: 1rem;
}

.authForm .inputBox {
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.authForm p span,
.inputBox label span {
  color: blue;
  cursor: pointer;
}

.inputBox label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
