.main_wrapper {

  background: linear-gradient(
      0deg,
      rgba(21, 96, 189, 0.33) 0%,
      rgba(21, 96, 189, 0.33) 100%
    ),
    url("../../assets/BG_3.png"), lightgray 50% / cover no-repeat;
    background-position: center;
}
