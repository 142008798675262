* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;

}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* This will hide the arrow buttons for all number inputs */
}
::-webkit-scrollbar {
  width: 3px !important;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #828282;
  cursor: pointer;
}
html {
  box-sizing: border-box;
}

.containerBox{
  height: calc(100vh - 89.7px);
  overflow: auto;
  background-color: #D9D9D9;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

button {
  transition: all 0.3s;
}

button:hover {
  cursor: pointer;
}
.react-datepicker-wrapper{
  width: 100%;
}