

.custom-upload-button {
    &__input {
        display: none;
    }

    &__label {
        text-align: center;
    
    
    transition: all 0.2s;
    cursor: pointer;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
   
    }
    &__loader{
        cursor: progress;
        background-color: #f5f5f5;
        border: none;
        color: #d9d9d9

    }
    &__disabled{
        background-color: #f5f5f5;
        cursor: not-allowed;
        border: 1px solid #d9d9d9;
    }
}