
@mixin ripple($duration, $delay) {
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    height: 100%;
    width: 100%;
    background: #0460CC;
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: $duration;
    animation-delay: $delay;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(.65,0,.34,1);
    z-index: -1;
}

.step {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 80px;
  margin: auto;
  border-radius: 100%;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  
  span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
  }
  
  &::after {
    @include ripple(2s, 0s);
  }
}


@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  
  to {
    opacity: 0;
    transform: scale3d(2,2,1);
  }
}

.empty{
  text-align: center;
  h1{
    font-weight: 500;
    font-size: 22px;
    width: 300px;
    margin-top: 30px;
  }
  p{
    margin-top: 10px;
    font-size: 15px;
  }
}


.emptyCom{
  text-align: center;
  h1{
    font-weight: 500;
    font-size: 18px;
    margin-top: 30px;
  }
  p{
    margin: 10px 0px;
    font-size: 13px;
  }
}

.stepCom {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  margin: auto;
  margin-top: 20px;
  border-radius: 100%;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.25);
  
  span {
    position: relative;
    font-size: 72px;
    top: 5px;
    left: -5px;
  }
  
  &::after {
    @include ripple(2s, 0s);
  }
}