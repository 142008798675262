.wrapper {
  bottom: 0;
  /* -webkit-transform: translate3d(0, 0, 0); */
  transform: translate3d(0, 0, 0);
  display: flex;

  background-color: red;
  /* &.darkMode.static {
    background-color: transparent;
    box-shadow: none;
  } */
}

.overflowText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
